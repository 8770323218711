<template>
  <div class="contentDiv">
    <p class="headerCon">AiLa user agreement and privacy policy</p>
    <div class="contentText">
      <span class="headerTop">AiLa highly values your privacy.</span> 
      <p>In order to facilitate you to login, enjoy relevant services and provide you with more personalized service experience, we may collect and use your information when you use our services and your interactions with AiLa. We hope this Policy will help you understand how we collect, use, store and disclose your information when you use AiLa platform and website service (https://www.ailaworld.com/) (hereinafter referred to as “ Services”), plus the method we provide to access, update, control and protect these information. Please read carefully.<p>
      <p>When you use our Services, you are agreeing to our policies regarding your personal information and you expressly consent to us collecting, using, storing and disclosing your personal information, as described in this Privacy Policy.</p>
      <span class="contentTitle">I. INFORMATION COLLECTION </span>  
      <p>1. In order to complete the necessary process before using Aila platform such as registration, management and real-name authentication, you may be required to provide true, lawful and valid information, including but not limited to name, contact person, phone number, email address, contact address, business registration and bank account information.  </p>
      <P>2. For the purpose to provide continuous services and guarantee high quality of service, we automatically collect, record and store your information when you login and use our Services, including but not limited to IP address, language used, date of login and web browsing history.</P>
      <p>3. If you need to use some customized services such as one-to-one online video conference, you may be required to provide additional information such as personal contact information, ID information, photos and device identification number.</p>
      <span class="contentTitle">II. HOW WE COLLECT INFORMATION </span> 
      <p>1. We collect your information through your voluntary submission and the corresponding records generated during your entry and use of our service.</p>
      <p>2. We use Cookies and other similar technologies to collect and use your information, which enable us to provide certain features and function:</p>
      <p>a) Verify your AiLa account. For example, Cookies help us verify you as our registered user or member.</p>
      <p>b) Analyze your preference when you use Services, to provide you with more customized services including personalized pages, business recommendations.</p>
      <p>3. You have the option to permit installation of such Cookies or subsequently disable them. You may refuse or accept Cookies by adjusting the relevant settings in your browser. However, in the event of your refusal to install Cookies, AiLa may be prevented from providing some of its Services available to you.</p>
      <p>4. The information collected through Cookies will be governed by this Privacy Policy.</p>
      <span class="contentTitle">III. HOW WE USE INFORMATION </span> 
      <p>1. In order to provide you with high-quality, convenient and safe services, we may use the information for the following purposes in accordance with relevant laws and regulations. </p>
      <p>a) Provide you with Services</p>
      <p>b) Satisfy your individual requirements, such as language settings, location settings, personal guide and instruction, or other response to you or other members</p>
      <p>c) Optimize and develop the Services. We will optimize our services and adjust settings based on the information collected when you login the website and use the Services.</p>
      <p>d) Protect AiLa platform, AiLa members and business partners. We may use your information for identity verification, security precautions, complaint handling, dispute coordination, and fraud monitoring.</p>
      <p>e) Other relevant situation that may collect your information. If such situation is not reasonably related to the original situation or purpose, we will obtain your consent before using the information.</p>
      <span class="contentTitle">IV.  INFORMATION DISCLOSURE</span>
      <p>Without your approval, we will not disclose or share your information with any other third parties, except the following circumstances:</p>
      <p>1. We may share your information with our affiliates, third-party service providers, contractors and agents only for the following purposes:</p>
      <p>a) Necessary to provide the corresponding functions or services in Section I and for the purposes stated in the Section III.</p>
      <p>b) Fulfill our obligations in the "AiLa Member IT Service Agreement" or this Policy and exercise our rights.</p>
      <p>If we share your information with any of the foresaid third parties, we will endeavor to ensure that such third party complies with this Policy and other appropriate confidentiality and security measures we require when using your information.</p>
     <p>2. With the development of our business, we will disclose information to a third party in the event that we sell or buy any business or undergo a merger or acquisition, in which case will disclose your data to the prospective buyer of such transaction. We will comply with the requirements of relevant laws and regulations, notify you before the transaction and guarantee the confidentiality of the information during the transaction.</p>
     <p>3. We may also disclose your information for the following reasons:</p>
     <p>a) To comply with the applicable laws and regulations.</p>
     <p>b) To comply with court judgement, verdict or requirements of other legal procedures.</p>
     <p>c) To comply with the requirements of relevant government departments or other authorities.</p>
     <p>d) We have reason to believe that we need to comply with applicable laws and regulations.</p>
     <p>e) Reasonable and necessary purposes to perform relevant agreements or this Policy, or to protect public interest, to handle complaints or disputes and to protect personal safety, property, and other lawful rights and interests of our clients, ourselves, our affiliates, our members or employees.</p>
     <p>f) By obtaining your approval.</p>
     <p>If we disclose your information for the above reasons, we will inform you in a timely manner in accordance with the relevant laws and regulations and this Policy.</p>
     <span class="contentTitle">V. INFORMATION STORAGE</span>
     <p>1. Your personal information collected by AiLa is stored on our servers which are in mainland China.</p>
     <p>2. In general, we will retain your personal information in the necessary period to fulfill the purpose of collection of such information. However, for the following purposes, we may change the retention period of your personal information:</p>
     <p>a) To comply with the applicable laws and regulations.</p>
     <p>b) To comply with court judgement, verdict or requirements of other legal procedures.</p>
     <p>c) To comply with the requirements of relevant government departments or other authorities.</p>
     <p>d) We have reason to believe that we need to comply with applicable laws and regulations.</p>
     <p>e) Reasonable and necessary purposes to perform relevant agreements or this Policy, or to protect public interest, to handle complaints or disputes and to protect personal safety and property other lawful rights and interests of our clients, ourselves, our affiliates, our members or employees.</p>
     <span class="contentTitle">VI. INFORMATION SECURITY</span>
     <p>1. We take security measures to protect your information against loss, improper use, unauthorized access or disclose. We work within the reasonable security standards and use a variety of security measures to protect the security of information. Unfortunately, the transmission of information via internet is not completely secure due to technical limitations and potential hacker attack. Although we will take best efforts to protect your personal information, we cannot guarantee the absolute security of the information transmitted to our site or platform. Any transmission of your information is at your own risk.</p>
     <p>In the event of a breach of security incident of personal information, we will activate emergency response plan to prevent the expansion of such security incident and inform you in the form of emails, push notifications, public announcements, etc. Meanwhile, we will provide you with suggestions on information security. </p>
     <p>2. Please keep your account and password information properly to avoid personal information leakage. AiLa will be only responsible for the extent that AiLa platform or website directly leads to the disclosure of your personal information.</p>
     <span class="contentTitle">VII. ACCESS AND MANAGEMENT </span>
     <p>1. You may access, amend and delete personal information provided by you when using our services. The scope and the way that you access, amend or delete your personal information depending on the service you use.</p>
     <p>2. If you find that our collection or use of your personal information is not in accordance with the t the laws and regulations or the agreement of both parties, you can require us to delete such information. If you find that any of your personal information that we collected and stored is inaccurate and cannot be corrected by yourself, you can send a request to us. </p>
     <p>3. For information security purposes, we may ask you to verify your identity before we allow you to access, amend and delete relevant information.</p>
     <p>4. Please understand that due to technical limitations, legal or regulatory requirements, we may not be able to access, amend and delete part of your personal information.</p>
     <p>5. We process your information with your consent to promote additional functions on AiLa platform and Services to you. You can withdraw such consent at any time and contact the customer service to delete your member account of AiLa platform.</p>
     <span class="contentTitle">VIII. CHANGES TO PRIVACY POLICY</span>
     <p>We may update and revise this Privacy Policy from time to time, and if such changes result in a substantial impairment of your rights or expansion of the scope to collect and use personal information, we will inform you by sending you an email or by reminding you at prominent place on the platform or website before such changes take effect. Under such circumstances, if you continue to use our services, you will be regarded to have agreed to be bound by the updated policy.</p>
     <span class="contentTitle">IX. CONTACT US</span>
     <p>If you have any questions or comments in relation to this Privacy Policy or other related matters, please contact us via info@ailaworld.com.</p>
     <p>We will review your queries and reply within 30 days after verifying your user identity. </p>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.contentDiv {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 0 20px;
  .headerCon {
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    margin: 20px 0;
  }
  
}
.contentText {
    text-indent:2em;
    font-size: 20px;
    line-height: 40px;
  }
  .contentTitle {
      font-size: 20px;
      font-weight: 600;
      margin: 20px 0;
      display: inline-block;
      text-indent:0em;
  }
  .textCenter{
      font-weight: 900;
  }
  .headerTop{
      display: inline-block;
      margin-bottom: 20px;
      text-indent:0em;
      font-weight: 500;
  }
</style>
